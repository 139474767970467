<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Save"
      :ok-disabled="isDisableBtn"
      header-close-content=""
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-row>
          <!-- Name Input -->
          <b-col cols="12">
            <b-form-group id="input-name">
              <label for="nameInput">Name: <span class="text-danger">*</span></label>
              <b-form-input
                id="nameInput"
                name="name"
                v-model="editedItem.name"
                type="text"
                aria-describedby="input-name"
                data-vv-as="name"
                :class="{ input: true, 'is-danger': errors.has('name') }"
                v-validate="{ required: true, min: 1, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('name')" class="is-danger-text position-relative">{{
                errors.first('name')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- select Role -->
          <b-col cols="12">
            <b-form-group id="input-role" label-for="Role">
              <label for="roleInput">Role: <span class="text-danger">*</span></label>
              <b-form-select
                v-model="selectedRole"
                :options="allRoleAdsList"
                @change="updateRole"
                name="role"
                data-vv-as="role"
                :class="{ input: true, 'is-danger': errors.has('role') }"
                v-validate="{
                  required: true,
                  excluded: '--Please select Role--'
                }"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">--Please select Game--</b-form-select-option>
                </template></b-form-select
              >
              <span v-show="errors.has('role')" class="is-danger-text position-relative">{{
                errors.first('role')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Email -->
          <b-col cols="12">
            <b-form-group id="input-email">
              <label for="emailInput">Email: <span class="text-danger">*</span></label>
              <b-form-input
                id="emailInput"
                name="email"
                v-model="editedItem.email"
                type="text"
                aria-describedby="input-email"
                data-vv-as="email"
                :class="{ input: true, 'is-danger': errors.has('email') }"
                v-validate="{ email: true, required: true, min: 3, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('email')" class="is-danger-text position-relative">{{
                errors.first('email')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Password add -->
          <b-col cols="12" v-if="idCreated">
            <b-form-group id="input-password">
              <label for="passwordInput">Password: <span class="text-danger">*</span></label>
              <b-form-input
                id="passwordInput"
                name="password"
                v-model="editedItem.password"
                type="password"
                aria-describedby="input-password"
                data-vv-as="password"
                :class="{ input: true, 'is-danger': errors.has('password') }"
                v-validate="{ required: true, min: 8, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('password')" class="is-danger-text position-relative">{{
                errors.first('password')
              }}</span>
            </b-form-group>
          </b-col>
          <!-- Password update-->
          <b-col cols="12" v-else>
            <b-form-group id="input-password">
              <label for="passwordInputUdate">Password Update: <span class="text-danger">*</span></label>
              <b-form-input
                id="passwordInputUdate"
                name="password_update"
                v-model="editedItem.password_update"
                type="password"
                aria-describedby="input_password_update"
                data-vv-as="password"
                :class="{
                  input: true,
                  'is-danger': errors.has('password_update')
                }"
                v-validate="{ min: 8, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('password_update')" class="is-danger-text position-relative">{{
                errors.first('password_update')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- status -->
          <b-col cols="12">
            <b-form-group id="input-status" label="Status:" label-for="Status">
              <b-form-select v-model="filter_status" :options="statusList" name="Status">
                <template v-slot:first>
                  <b-form-select-option :value="null">-- Please select Status --</b-form-select-option>
                </template></b-form-select
              >
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">User List</h4>
      </b-col>

      <!-- FilterEmail -->
      <b-col cols="4">
        <b-form-group id="input-email-filter" label="Email" label-for="email-filter">
          <b-form-input
            id="email-filter"
            v-model="filter_email"
            type="text"
            placeholder="Search email..."
            @input="onFilterEmail"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <!-- Filter Active -->
      <b-col cols="2">
        <b-form-group id="input-status-filter" label="Status" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>

      <b-col cols="4" class="btnAdd">
        <b-button variant="primary" class="float-right" @click="addItem">Add</b-button>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table text-center"
    >
      <template v-slot:cell(status)="item">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Active</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0 && item.item.deleted_at" variant="danger">Deleted</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Inactive</b-badge>
      </template>
      <template v-slot:cell(actions)="item">
        <v-icon class="text-success" @click="editItem(item.item)">mdi-pencil</v-icon>
        <!-- <v-icon class="text-danger"  @click="deleteItem(item.item)"
          >mdi-delete</v-icon
        > -->
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="fetchData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import _ from 'lodash';
import axios from 'axios';
export default {
  name: 'User List',
  components: {},
  data() {
    return {
      idCreated: false,
      search: '',
      isBusy: false,
      filter_user: '',
      filter_status: null,
      filter_email: '',
      filter_role: '',
      statusList: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' }
      ],
      headers: [
        { text: 'Id', key: 'id' },
        { text: 'Name', key: 'name' },
        { text: 'Email', key: 'email' },
        { text: 'Role', key: 'role' },
        { text: 'Status', key: 'status', class: 'text-center' },
        { text: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data
      items: [],
      allRoleAdsList: [],
      selectedRole: null,
      modalTitle: '',
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        name: '',
        email: '',
        role_id: '',
        password: '',
        status: '',
        password_update: ''
      },
      dialog: false,
      editedIndex: -1,
      editorConfig: {
        language: 'de'
      }
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {
    isDisableBtn() {
      return (
        this.errors.has('name') ||
        !this.editedItem.email ||
        this.errors.has('email') ||
        this.errors.has('password') ||
        this.errors.has('password_update') ||
        !this.selectedRole
      );
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'User List' }]);
  },
  methods: {
    onFilterEmail: _.debounce(function () {
      this.fetchData();
    }, 500),
    fetchData() {
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      const adsUser = ApiService.get(
        'users',
        `?email=${this.filter_email}&role=${this.filter_role}&status=${
          this.filter_status == null ? '' : this.filter_status
        }&limit=${limit}&offset=${offset}`
      );
      const allRole = ApiService.get('allrole');
      axios
        .all([adsUser, allRole])
        .then(
          axios.spread((...response) => {
            const adsUserRes = response[0];
            const allRoleRes = response[1];
            this.items = adsUserRes.data.data.productList.map((ads) => {
              return {
                ...ads
              };
            });
            this.rows = adsUserRes.data.data.total;
            this.allRoleAdsList = allRoleRes.data.data;
            this.isBusy = false;
          })
        )
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    addItem: function () {
      this.dialog = true;
      this.modalTitle = 'Add User';
      this.idCreated = true;
    },
    editItem: function (item) {
      this.idCreated = false;
      this.dialog = true;
      this.modalTitle = 'Update User';
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.filter_status = item.status;
      // this.filter_email = item.email;
      // this.filter_role = item.role_id;
      this.selectedRole = item.role_id;
    },
    close() {
      this.dialog = false;
      // this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem = {
        id: '',
        name: '',
        email: '',
        role_id: '',
        password: '',
        status: ''
      };
      this.editedIndex = -1;
      this.selectedRole = null;
      this.filter_email = null;
      this.filter_role = null;
      this.filter_status = null;
    },
    save(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      this.editedItem.role_id = this.selectedRole;
      this.editedItem.status = this.filter_status;
      let userData = {
        name: this.editedItem.name,
        email: this.editedItem.email,
        password: this.editedItem.password || this.editedItem.password_update || undefined,
        role_id: this.editedItem.role_id,
        status: this.filter_status
      };
      if (this.editedIndex > -1) {
        // Update product
        axios({
          method: 'PUT',
          url: `users/${this.items[this.editedIndex].id}`,
          data: userData
        })
          .then((response) => {
            if (response.status === 204) {
              this.editedItem.status = this.filter_status;
              this.allRoleAdsList.some((role) => {
                if (role.value == this.selectedRole) {
                  this.editedItem.role = role.text;
                  this.editedItem.role_id = role.value;
                  return true;
                }
              });
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.close();
              this.$bvToast.toast('Users updated successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.$bvToast.toast(`errors`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      } else {
        // Add product
        axios({
          method: 'POST',
          url: 'users',
          data: userData,
          headers: { 'Content-Type': 'application/json' }
        })
          .then((response) => {
            if (response.data.status) {
              let newUser = { ...response.data.data, status: 1 };
              this.items.unshift({
                ...newUser
              });
              this.close();
              this.$bvToast.toast('Users added successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.$bvToast.toast('errors', {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          })
          .catch((errors) => {
            if (errors) {
              if (errors.response.data.data.code === '002.001.034') {
                this.$bvToast.toast(`${errors.response.data.data.message}`, {
                  title: null,
                  variant: 'danger',
                  autoHideDelay: 3000,
                  noCloseButton: true
                });
              }
            }
          });
      }
    },
    updateRole() {}
  }
};
</script>
